import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';

import SEO from '../components/SEO';
import Hero from '../components/Hero/Hero';
import TextBlock from '../components/TextBlock';
import CtaBlock from '../components/CtaBlock';
import CTAButton from '../components/CTAButton/CTAButton';
import { withQuoteWizard } from '../components/withQuoteWizard';
import ServiceIntroBlock from '../components/ServiceIntroBlock';

const styles = theme =>
  createStyles({
    cards: {
      backgroundColor: '#FFFFFF',
    },
  });

class ThePage extends React.Component {
  render() {
    const { classes, pageContext: { projectData }} = this.props;
  
    return (
      <React.Fragment>
        <SEO
          title={`${projectData.title} - Sportsfield Renovation project - Twin View Turf`}
          keywords={[
            `Sportsfield Renovation project`,
            `Turf`,
            `Lawns`,
            `Best Lawns`,
            `Australia’s Best Lawns`,
            `lawn producer`,
          ]}
        />
        <Hero heroImage={projectData.headline.heroImg} noQuote>
          <TextBlock text={projectData.title} />
        </Hero>

        <Grid container item className={classes.cards}>
          <ServiceIntroBlock
            smallerChecklist
            checklist={projectData.headline.bulletpoints}
            statementCopy={projectData.headline.content}
          />
        </Grid>

        <CtaBlock title="Want a price for field renovation?">
          <CTAButton
            ctaText="Call Now"
            ctaHref="/contact"
            size="large"
            inverted={true}
          />
        </CtaBlock>
      </React.Fragment>
    );
  }
}

export default withQuoteWizard(
  withStyles(styles, { withTheme: true })(ThePage)
);
